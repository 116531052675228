/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
// eslint-disable-next-line no-unused-vars
import { getProxiedServiceUrl, getBCProxiedServiceUrl, getProxiedUrl, getProxyURLforDocumentDetail } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';
import { DocumentUploadService } from 'gw-portals-document-js';
import UtilService from '../../wsib/services/UtilService';

export default class EDUAccountService {
    static getAuthHeader() {
        let accessToken = document
            .getElementsByTagName('html')[0]
            .getAttribute('authheader');
        accessToken = accessToken
            ? {
                Authorization: `Bearer ${accessToken}`,
                'ocp-apim-subscription-key': UtilService.getSubscriptionKey()
            }
            : undefined;
        return accessToken;
    }
    static uploadDocumentForAccount(
        file,
        documentMetadata,
        additionalHeaders = {}
    ) {
        return DocumentUploadService.send(
            getProxiedServiceUrl('accountDocumentUpload'),
            file,
            documentMetadata,
            additionalHeaders
        );
    }

    static accountDocUploadToken(params, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'generateUploadToken',
            params,
            this.getAuthHeader()
        );
    }

    /* Below service is for unified landing page */
    static unifiedLandingPageResponse(params, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'getAccountsContactSummaries',
            params,
            this.getAuthHeader()
        );
    }

    /* Below service is for unified landing page */
    static getAccountSummaryDetails(params, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'getAccountSummary_WSIB',
            params,
            this.getAuthHeader()
        );
    }

    static getAccountSubmittedDocuments(accountNumber, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'getAccountDocuments_WSIB',
            [accountNumber],
            this.getAuthHeader()
        );
    }
    
    static getAccountBcSubmittedDocuments(accountNumber, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getBCProxiedServiceUrl(serviceName),
            'getAccountStatements_WSIB',
            [accountNumber],
            this.getAuthHeader()
        );
    }
    static updateStatementReadIndicator(accountNumber, serviceName, docUID, additionalHeaders = {}) {
        return JsonRPCService.send(
            getBCProxiedServiceUrl(serviceName),
            'updateStatementReadIndicator',
            [docUID],
            this.getAuthHeader()
        );
    }

    /*
     * @param {string} params
     * account send message
     */
    static accountSendMessage(params, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'saveMessage',
            [params],
            this.getAuthHeader()
        );
    }

    /*
     * @param {string} params
     * account message count
     */
    static accountMessageCount(params, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'getNewMessageCountForEmployer',
            [params],
            this.getAuthHeader()
        );
    }

    static getDocumentDetail(document, serviceName, showDescriptionModal) {
        const endpointUrl = getProxyURLforDocumentDetail(serviceName, document);
        return this.getDocument(endpointUrl, this.getAuthHeader(), showDescriptionModal);
    }


    static getDocument(downloadEndpointURL, authHeader, errorCallback = _.noop) {
        const params = {
            method: 'GET',
            url: downloadEndpointURL,
            headers: {
                ...authHeader
            },
        };
        return fetch(downloadEndpointURL, params).then((response) => {
            if (response.ok) {
                response.blob().then((blob) => {
                    console.log('response', blob);
                    const filename = response.headers.get('Content-Disposition').replace('attachment; filename=', '');
                    if (window.navigator.msSaveOrOpenBlob) {
                        navigator.msSaveBlob(blob, filename);
                    } else {
                        let testBlob;
                        // const blob = new Blob(['Hello, I am Blob content'], { type: 'drs/xlsx' });
                        if (blob.type === 'drs/docx' || blob.type === 'drs/doc') {
                            console.log(' in doc');
                            testBlob = blob.slice(0, blob.size, 'application/msword');
                        }
                        if (blob.type === 'drs/xls' || blob.type === 'drs/xlsx') {
                            console.log(' in xlsx');
                            testBlob = blob.slice(0, blob.size, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                        }
                        if (blob.type === 'drs/pdf') {
                            console.log(' in pdf');
                            testBlob = blob.slice(0, blob.size, 'application/pdf');
                        }
                        console.log('myBlob', testBlob);
                        console.log('blob', blob);
                        const updatedBlob = testBlob || blob;
                        console.log('updated blob', updatedBlob);
                        const downloadLink = document.createElement('a');
                        downloadLink.setAttribute('href', window.URL.createObjectURL(updatedBlob));
                        downloadLink.setAttribute('download', filename);
                        downloadLink.style.display = 'none';
                        document.body.appendChild(downloadLink);
                        // downloadLink.click();
                        // downloadLink.remove();
                        const blobURL = window.URL.createObjectURL(updatedBlob);
                        window.open(blobURL, '_blank');
                        URL.revokeObjectURL(blobURL);
                    }
                });
            } else {
                console.log('error in getDocument method');
                errorCallback();
            }
        }).catch((e) => {
            console.log('error in catch block', e);
            errorCallback();
        });
    }

    static getEmployerMessagesForCustomer(params, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'getEmployerMessagesForCustomer',
            [params],
            this.getAuthHeader()
        );
    }

    static getMessageThread(params, serviceName, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl(serviceName),
            'getMessageThread',
            params,
            this.getAuthHeader()
        );
    }
}
