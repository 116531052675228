/* eslint-disable prefer-promise-reject-errors */
import _ from 'lodash';
import { JsonRPCService } from 'gw-portals-transport-js';
import { getProxiedUrl } from 'gw-portals-url-js';
import { events } from 'gw-platform-events-js';

import iframeUtil from './IframeUtil/IframeUtil';
import jwtHelper from './JwtHelper';
import ERRORS from '../AuthErrors';

function isErrorCode(baseError, codeToCheck) {
    if (baseError instanceof Error) {
        const errorCode = baseError.message.match(/[0-9]+/);
        return errorCode && errorCode[0] === codeToCheck;
    }
    return baseError.error.message.includes(codeToCheck);
}

export class AuthenticationUtil {
    constructor(authConfig) {
        // singleton
        if (AuthenticationUtil.instance) {
            if (!_.isEqual(authConfig, AuthenticationUtil.instance.authConfig)) {
                throw new Error('Attempt to create AuthenticationUtil with a different configuration');
            }
            // eslint-disable-next-line no-constructor-return
            return AuthenticationUtil.instance;
        }
        AuthenticationUtil.instance = this;

        this.authConfig = authConfig;
        /** @type {Array<Function>} */
        this.authStatusListeners = [];
    }

    emitLoginEvent = (userData) => {
        const newUserData = {
            ...userData,
           //user_name: userData.userInfo[this.authConfig.usernameProperty]
        };
        const authData = {
            isLoggedIn: true,
            userData: newUserData
        };
        this.emitLoginStateChangeEvent(authData);
    };

    emitLogoutEvent = (evtData) => {
        const authData = {
            isLoggedIn: false,
            userData: null,
            evtData
        };
        this.emitLoginStateChangeEvent(authData);
    };

    addLoginStateChangeListener = (listener) => {
        this.authStatusListeners.push(listener);
    };

    // eslint-disable-next-line no-unused-vars
    prepareLogin = async ({ onRefreshError }) => {
        // extension point
        throw new Error('test for tokens not implemented');
    };

    forgotPassword = async (email, additionalHeaders = {}) => {
        const newPasswordEntryUrl = getProxiedUrl('auth/resetpassword');
        const params = [{ email, newPasswordEntryUrl }];

        try {
            const res = await JsonRPCService.send(getProxiedUrl('resetpassword'), 'sendPasswordToken', params, additionalHeaders);
            return ({ res: res });
        } catch (error) {
            // email does not exists when error message return code 404
            if (isErrorCode(error.baseError, '404')) {
                return Promise.reject({
                    error: ERRORS.emailNotFound
                });
            }
            return Promise.reject(error);
        }
    };

    // eslint-disable-next-line camelcase
    changePassword = async ({ code, new_password }, additionalHeaders = {}) => {
        const params = [{ code, new_password }];

        try {
            return JsonRPCService.send(getProxiedUrl('resetpassword'), 'newPassword', params, additionalHeaders);
        } catch (error) {
            // invalid token or password returns error message code 422
            if (isErrorCode(error.baseError, '422')) {
                return Promise.reject({
                    error: ERRORS.invalidTokenOrEmail
                });
            }
            return Promise.reject(error);
        }
    };

    signUp = async ({
        givenName,
        familyName,
        userName,
        email,
        password
    }) => {
        const params = [{
            name: { givenName, familyName },
            emails: [{ value: email, primary: true }],
            userName,
            password
        }];

        try {
            const res = await JsonRPCService.send(getProxiedUrl('signup'), 'createUser', params);
            return { res };
        } catch (err) {
            const errorCode = err.baseError.error.message.match(/[0-9]+/gi);
            // user already exists if error message return code 409
            if (errorCode && errorCode.includes('409')) {
                throw new Error({
                    error: ERRORS.userAlreadyExists
                });
            }
            throw err;
        }
    };

    verifyResetCode = async (code) => {
        const iframeData = await iframeUtil.loadIframe({
            src: getProxiedUrl(`reset_password?code=${code}`)
        });
        /**
         * @type {HTMLInputElement}
         */
        const codeIframeInput = iframeData.iframeDoc.querySelector('[name="code"]');
        const newCode = codeIframeInput.value;
        return {
            res: newCode
        };
    };

    // an alternative to registering a listener with AuthenticationService
    // is to listen for loginState::change events
    emitLoginStateChangeEvent = (authData) => {
        events.emit('loginState::change', authData);
        this.authStatusListeners.forEach((listener) => listener(authData));
    };

    getIdTokenDetails = async (tokens) => {
        /*** Commented due to make the call to userinfo API to get firstname & lastname ***/
        /*if (tokens.idToken) {
            const userInfo = jwtHelper.decodeToken(tokens.idToken);
            return { userInfo, ...tokens };
        }*/
        const { url, endpoints } = this.authConfig;
        const authUrl = getProxiedUrl(url + endpoints.userinfo);
        const response = await fetch(authUrl, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${tokens.accessToken}`
            }
        });
        if (!response.ok) {
            throw new Error(`${authUrl} - ${response.status} - ${response.statusText}`);
        }
        const userInfo = await response.json();
        return { ...userInfo, ...tokens };
    };
}

export default (oAuthConfig) => {
    return new AuthenticationUtil(oAuthConfig);
};
