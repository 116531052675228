import { defineMessages } from "react-intl";

export default defineMessages({
  heading: {
    id: "send-message.heading",
    defaultMessage: "Send us a message"
  },
  para1: {
    id: "send-message.para1",
    defaultMessage:
      "If you have a question about this claim, select a subject from the drop-down below and provide a brief summary in your message. All information you share will be added to the claim file.Please do not send any account-related messages."
  },
  para2: {
    id: "send-message.para2",
    defaultMessage:
      "A customer service representative will respond to your message within"
  },
  para3: {
    id: "send-message.para3",
    defaultMessage: "for more information."
  },
  para4: {
    id: "send-message.para4",
    defaultMessage: "."
  },
  two: {
    id: "send-message.two",
    defaultMessage: "one"
  },
  FAQLink: {
    id: "send-message.FAQLink",
    defaultMessage: "frequently asked questions"
  },
  Subject: {
    id: "send-message.Subject",
    defaultMessage: "Subject:"
  },
  Body: {
    id: "send-message.Body",
    defaultMessage: "Body:"
  },
  Send: {
    id: "send-message.Send",
    defaultMessage: "Send message"
  },
  contactUsText: {
    id: "send-message.contactUsText",
    defaultMessage: "If you have an immediate request  please"
  },
  contactUsLink: {
    id: "send-message.contactUsLink",
    defaultMessage: "contact us"
  },
  ChooseOne: {
    id: "send-message.Chooseone",
    defaultMessage: "Choose one"
  },
  ClaimStatusInformation: {
    id: "send-message.ClaimStatusInformation",
    defaultMessage: "Claim status information"
  },
  Payments: {
    id: "send-message.Payments",
    defaultMessage: "Payments"
  },
  ReturnToWork: {
    id: "send-message.ReturnToWork",
    defaultMessage: "Return to work"
  },
  Treatment: {
    id: "send-message.Treatment",
    defaultMessage: "Treatment"
  },
  GeneralQuestion: {
    id: "send-message.GeneralQuestion",
    defaultMessage: "General question"
  },
  Appeals: {
    id: "send-message.Appeals",
    defaultMessage: "Appeals"
  },
  MSG41: {
    id: "send-message.MSG41",
    defaultMessage:
      "Please try again later. If you’re still having trouble with this page, call us at: 1-800-387-0750."
  },
  MSG22: {
    id: "send-message.MSG22",
    defaultMessage:
      "Are you sure you want to leave this page?\nYou will lose the information you entered."
  },
  StayOnPage: {
    id: "send-message.StayOnPage",
    defaultMessage: "Stay on this page"
  },
  LeavePage: {
    id: "send-message.LeavePage",
    defaultMessage: "Leave this page"
  },
  MSG79: {
    id: "send-message.MSG79",
    defaultMessage:
      "Are you sure you want to continue?\nYou will lose the information you entered"
  },
  Okay: {
    id: "send-message.Okay",
    defaultMessage: "Okay"
  },
  Cancel: {
    id: "send-message.Cancel",
    defaultMessage: "Cancel"
  },
  characterCount: {
    id: "send-message.characterCount",
    defaultMessage: "Character count"
  }
});
