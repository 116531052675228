/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import { LocaleService } from '@jutro/locale';


const contentEnglish = {
    pageViewPrivacyPolicy: 'DI7_Privacy_PageView',
    PrivacyNotAccepted: 'DI7_Error_PrivacyNotAccepted',
    generalErrorMessage: 'DI7_Error_InvalidEntry',
    timeSpentOnprivacyPolicy: 'DI7_Privacy_TimeSpent',
    pageViewSelectReportType: 'DI7_SelectReportType_PageView',
    timeSpentSelectReportType: 'DI7_SelectReportType_TimeSpent',
    pageViewExposureForm: 'DI7_ExposureForm_PageView',
    timeSpentExposureForm: 'DI7_ExposureForm_TimeSpent',
    pageViewSelectExposureCategory: 'DI7_SelectCatExposure_PageView',
    timeSpentSelectExposureCategory: 'DI7_SelectCatExposure_TimeSpent',
    pageViewSelectCatInjuryIllness: 'DI7_SelectCatInjuryIllness_PageView',
    timeSpentSelectCatInjuryIllness: 'DI7_SelectCatInjuryIllness_TimeSpent',
    pageViewSelectNOIPhysical: 'DI7_SelectNOIPhysical_PageView',
    timeSpentSelectNOIPhysical: 'DI7_SelectNOIPhysical_TimeSpent',
    selectNOIPhysicalSuddenSpecific: 'DI7_SelectNOIPhysical_SuddenSpecific',
    selectNOIPhysicalOneTimeTooltip: 'DI7_SelectNOIPhysical_OneTimeTooltip',
    selectNOIPhysicalATNoneTooltip: 'DI7_SelectNOIPhysical_ATNoneTooltip',
    selectNOIPhysicalNOINoneTooltip: 'DI7_SelectNOIPhysical_NOINoneTooltip',
    assaultToolTip: 'DI7_SelectNOIPhysical_ATooltipAssa',
    bendToolTip: 'DI7_SelectNOIPhysical_ATooltipBend',
    caughtToolTip: 'DI7_SelectNOIPhysical_ATooltipCaugInju',
    forgienBodyObject: 'DI7_SelectNOIPhysical_ATooltipForeObje',
    extremeWeather: 'DI7_SelectNOIPhysical_ATooltipExtrWeat',
    incorrectErgonomicsToolTip: 'DI7_SelectNOIPhysical_ATooltipIncoErgo',
    liftToolTip: 'DI7_SelectNOIPhysical_ATooltipLift',
    overExertionToolTip: 'DI7_SelectNOIPhysical_ATooltipOverOver',
    prolongedPositionToolTip: 'DI7_SelectNOIPhysical_ATooltipProgPosi',
    pushPullToolTip: 'DI7_SelectNOIPhysical_ATooltipPushPull',
    repeativeForceToolTip: 'DI7_SelectNOIPhysical_ATooltipRepeForc',
    repeativeMovementToolTip: 'DI7_SelectNOIPhysical_ATooltipRepeMove',
    uncustomedTooltip: 'DI7_SelectNOIPhysical_ATooltipUnacMove',
    twistToolTip: 'DI7_SelectNOIPhysical_ATooltipTwis',
    repetativeStrainToolTip: 'DI7_SelectNOIPhysical_NTooltipRepeStra',
    bodyFluidIllnessToolTip: 'DI7_SelectNOIIllness_ITooltipBodyFlui',
    enerticIllnessToolTip: 'DI7_SelectNOIIllness_ITooltipEnte',
    havsIllnessToolTip: 'DI7_SelectNOIIllness_ITooltipHAVS',
    needleStickToolTip: 'DI7_SelectNOIIllness_ITooltipNeed',
    neuroDiseaseIllnessTollTip: 'DI7_SelectNOIIllness_ITooltipNeurDise',
    selectNOIPhysicalATTooltip1: 'DI7_SelectNOIPhysical_ATTooltip1',
    selectNOIPhysicalATTooltip2: 'DI7_SelectNOIPhysical_ATTooltip2',
    selectNOIPhysicalNOITooltip1: 'DI7_SelectNOIPhysical_NOITooltip1',
    selectNOIPhysicalNOITooltip2: 'DI7_SelectNOIPhysical_NOITooltip2',
    selectNOIPhysicalWarningChange: 'DI7_SelectNOIPhysical_WarningChange',
    selectNOIPhysicalGradual: 'DI7_SelectNOIPhysical_Gradual',
    employerMailingTimeSpent: 'DI7_EmployerMailing_TimeSpent',
    employerMailingPageView: 'DI7_EmployerMailing_PageView',
    mandatoryFieldBlankError: 'DI7_Error_MandatoryFieldBlank',
    employerMailingNoMatch: 'DI7_Warning_EmployerMailingNoMatch',
    employerBranchPageView: 'DI7_EmployerBranch_PageView',
    employerBranchTimeSpent: 'DI7_EmployerBranch_TimeSpent',
    onboardingCompletePageView: 'DI7_OnboardingComplete_PageView',
    onboardingCompleteTimeSpent: 'DI7_OnboardingComplete_TimeSpent',
    errorGenerateReference: 'DI7_Error_GenerateReference',
    employerInformationPageView: 'DI7_EmployerInformation_PageView',
    employerInformationTimeSpent: 'DI7_EmployerInformation_TimeSpent',
    employerInformationErrorRetrieve: 'DI7_EmployerInformation_ErrorRetrieve',
    invalidEntry: 'DI7_Error_InvalidEntry',
    maxCharLimit: 'DI7_Error_MaxCharLimit',
    pageViewSelectCatSeriousInjury: 'DI7_SelectNOISeriousInj_PageView',
    timeSpentSelectCatSeriousInjury: 'DI7_SelectNOISeriousInj_TimeSpent',
    assaultSeriousToolTip: 'DI7_SelectNOISeriousInj_ATooltipAssa',
    crushSeriousToolTip: 'DI7_SelectNOISeriousInj_ATooltipCrus',
    strikeSeriousToolTip: 'DI7_SelectNOISeriousInj_ATooltipStri',
    strikePhysicalToolTip: 'DI7_SelectNOIPhysical_ATooltipStri',
    changeAsualtLinkFromSI: 'DI7_SelectNOISeriousInj_WarningChange',
    pageViewSelectNOIMental: 'DI7_SelectNOIMental_PageView',
    timeSpentSelectNOIMental: 'DI7_SelectNOIMental_TimeSpent',
    selectNOIMentalSingleEvent: 'DI7_SelectNOIMental_SingleEvent',
    selectNOIMentalCumulativeEvent: 'DI7_SelectNOIMental_CumulativeEvent',
    selectNOIMentalHarassment: 'DI7_SelectNOIMental_Harassment',
    pageViewreviewFatalityInfo: 'DI7_ReviewFatalityInfo_PageView',
    timeSpentreviewFatalityInfo: 'DI7_ReviewFatalityInfo_TimeSpent',
    pageViewSelectNOINIHL: 'DI7_SelectNOINIHL_PageView',
    timeSpentSelectNOINIHL: 'DI7_SelectNOINIHL_TimeSpent',
    pageViewNOIIllness: 'DI7_SelectNOIIllness_PageView',
    timeSpentNOIIllness: 'DI7_SelectNOIIllness_TimeSpent',
    warningMessageSelectNOINIHL: 'DI7_SelectNOINIHL_WarningChange',
    pageViewReporterDetails: 'DI7_ReporterDetails_PageView',
    pageViewEmpReporterDetails: 'DI7_ReporterDetails_Employer',
    pageviewEmprepReporterDetails: 'DI7_ReporterDetails_EmployerRep',
    timeSpentReporterDetails: 'DI7_ReporterDetails_TimeSpent',
    timeSpentEmpReporterDetails: 'DI7_ReporterDetailsEmp_TimeSpent',
    timeSpentEmpRepReporterDetails: 'DI7_ReporterDetailsEmpRep_TimeSpent',
    pageviewOnboardingComplete: 'DI7_OnboardingComplete_PageView',
    timeSpentOnboardingComplete: 'DI7_OnboardingComplete_TimeSpent',

    pageViewAssociatedClaimNumber: 'DI7_AssociatedClaimNumber_PageView',
    timeSpentAssociatedClaimNumber: 'DI7_AssociatedClaimNumber_TimeSpent',
    toolTipAssociatedClaimNumber: 'DI7_AssociatedClaimNumber_ClaimTooltip',
    pageViewPWIContact: 'DI7_PWIContact_PageView',
    timeSpentPWIContact: 'DI7_PWIContact_TimeSpent',
    toolTipPWIContact: 'DI7_PWIContact_NoPhoneNumberTooltip',
    pageViewPWIAdditionalInfo: 'DI7_PWIAdditional_PageView',
    genderToolTipPWIAdditionalInfo: 'DI7_PWIAdditional_GenderTooltip',
    sinToolTipPWIAdditionalInfo: 'DI7_PWIAdditional_SINTooltip',
    timeSpentPWIAdditionalInfo: 'DI7_PWIAdditional_TimeSpent',
    pageViewPWIAddress: 'DI7_PWIAddress_PageView',
    timeSpentPWIAddress: 'DI7_PWIAddress_TimeSpent',
    pageViewWorkHistoryExposure: 'DI7_WorkHistoryExposure_PageView',
    timeSpentWorkHistoryExposure: 'DI7_WorkHistoryExposure_TimeSpent',
    pageViewHealthSafetyCEIR: 'DI7_HealthSafetyCEIR_PageView',
    timeSpentHealthSafetyCEIR: 'DI7_HealthSafetyCEIR_TimeSpent',
    warningDocRemoveHealthSafetyCEIR: 'DI7_HealthSafetyCEIR_WarningDocRemove',
    documentUploadHealthSafetyCEIR: 'DI7_HealthSafetyCEIR_UploadDoc',
    pageViewHealthSafetyPEIR: 'DI7_HealthSafetyPEIR_PageView',
    timeSpentHealthSafetyPEIR: 'DI7_HealthSafetyPEIR_TimeSpent',
    documentUploadHealthSafetyPEIR: 'DI7_HealthSafetyPEIR_UploadDoc',
    pageViewRelationshipExposure: 'DI7_RelationshipExposure_PageView',
    timeSpentRelationshipExposure: 'DI7_RelationshipExposure_TimeSpent',
    pageViewExposureDetail: 'DI7_ExposureDetail_PageView',
    timeSpentExposureDetail: 'DI7_ExposureDetail_TimeSpent',
    editReportExposureDetail: 'DI7_ExposureDetail_EditReport',
    pageViewAdditionalDetailExposure: 'DI7_AdditionalDetailExposure_PageView',
    timeSpentAdditionalDetailExposure: 'DI7_AdditionalDetailExposure_TimeSpent',
    exposureCategoryCIER: 'DI7_SelectCatExposure_CEIR',
    exposureCategoryPEIR: 'DI7_SelectCatExposure_PEIR',
    employerBranchNoMatch: 'DI7_Warning_BranchNoMatch',
    pageViewInfectiousDetails: 'DI7_InfectousDetailExposure_PageView',
    timeSpentInfectiousDetails: 'DI7_InfectousDetailExposure_TimeSpent',
    pageViewChemicalSubDetails: 'DI7_ChemicalDetailExposure_PageView',
    timeSpentChemicalSubDetails: 'DI7_ChemicalDetailExposure_TimeSpent',
    pageViewExposureLocation: 'DI7_IncidentLocationExposure_PageView',
    timeSpentExposureLocation: 'DI7_IncidentLocationExposure_TimeSpent',
    pageViewDateOfExposure: 'DI7_ExposureDate_PageView',
    timeSpentDateOfExposure: 'DI7_ExposureDate_TimeSpent',
    pageViewEmergencyMeasures: 'DI7_EmergencyDetailExposure_PageView',
    timeSpentEmergencyMeasure: 'DI7_EmergencyDetailExposure_TimeSpent',
    pwiAddressNoMatchFound: 'DI7_Warning_PWIMailingNoMatch',
    incidentAddressNotMatched: 'DI7_Warning_IncAddrNoMatch',
    errorRetrieveReportsSection: 'DI7_LandingAccount_ErrorRetrieve',
    viewReportsPageView: 'DI7_LandingNewReport_PageView',
    selectedAccountWOBusinessAct: 'DI7_Error_NoBusinessActivity',
    errorNoAccountSelected: 'DI7_Error_NoAccountSelected',
    errorReportsretriving: 'DI7_LandingNewReport_ErrorRetrieve',
    timeSpentViewReportsPage: 'DI7_LandingNewReport_TimeSpent',
    pageViewExposureList: 'DI7_LandingExposure_PageView',
    timeSpentExposureList: 'DI7_LandingExposure_TimeSpent',
    pageViewInjuryReports: 'DI7_LandingInjuryIllness_PageView',
    timeSpentInjuryReports: 'DI7_LandingInjuryIllness_TimeSpent',
    pageViewFatalityReports: 'DI7_LandingFatality_PageView',
    timeSpentFatalitysReports: 'DI7_LandingFatality_TimeSpent',
    errorRetriveExpsoureList: 'DI7_LandingExposure_ErrorRetrieve',
    errorRetriveFatalityReports: 'DI7_LandingFatality_ErrorRetrieve',
    errorRetriveInjuryReports: 'DI7_LandingInjuryIllness_ErrorRetrieve',
    deleteDraftInjuryReport: 'DI7_Warning_InjuryDeleteDraft',
    deleteDraftExposureReport: 'DI7_Warning_ExposureDeleteDraft',
    deleteDraftFatalityReport: 'DI7_Warning_FatalityDeleteDraft',
    selectNoCriteriaSerach: 'DI7_Error_SearchNoCriteria',
    slectResetViewClick: 'DI7_Error_ResetDefault',
    noReportExistsForAccount: 'DI7_Error_SearchNoReports',
    noSearchMatchFound: 'DI7_Error_SearchNoMatch',
    pageViewPWIEmploymentDetails: 'DI7_WorkHistory_PageView',
    tieSpentPWIEmploymentDetails: 'DI7_WorkHistory_TimeSpent',
    employmentJobTitleHelpClick: 'DI7_WorkHistory_JobTitleTooltip',
    pageViewWorkHistoryRelationShip: 'DI7_Relationship_PageView',
    timeSpentWorkHistoryRelationShip: 'DI7_Relationship_TimeSpent',
    pageViewAdditionalWorkplaceDetails: 'DI7_WorkplaceDetails_PageView',
    timeSpentAdditionalWorkplaceDetails: 'DI7_WorkplaceDetails_TimeSpent',
    documentCannotRemovedWarning: 'DI7_Warning_DocRemove',
    duplicateDocumentName: 'DI7_Error_FileNameSame',
    inValidDocumentName: 'DI7_Error_FileNameInvalid',
    documentSubmissionFailure: 'DI7_Error_DocumentUnsuccessful',
    documentMinFileSizeError: 'DI7_Error_FileMinimumSize',
    documentVirusDetectedError: 'DI7_Error_VirusDetected',
    pageViewSelectBusinessActivity: 'DI7_SelectBusActivityAct_PageView',
    timeSpentSelectBusinessActivity: 'DI7_SelectBusActivityAct_TimeSpent',
    inactiveActivityNotFoundWarning: 'DI7_Warning_InactiveActivitySearch',
    retrievingActivitiesError: 'DI7_SelectBusActivityAct_ErrorRetrieve',
    faqPageView: 'DI7_FAQ_PageView',
    helpLinksPageView: 'DI7_HelpfulLinks_PageView',
    pageViewThirdPartyInformation: 'DI7_ThirdParty_PageView',
    timeSpentThirdPartyInformation: 'DI7_ThirdParty_TimeSpent',
    involvedToolTipThirdPartyInformation: 'DI7_ThirdParty_InvolvedTooltip',
    pageViewInjuryDetailPhysical: 'DI7_InjuryDetailPhysical_PageView',
    pageViewInjuryDetailSerious: 'DI7_InjuryDetailSeriousInj_PageView',
    timeSpentInjuryDetailPhysical: 'DI7_InjuryDetailPhysical_TimeSpent',
    timeSpentInjuryDetailSerious: 'DI7_InjuryDetailSeriousInj_TimeSpent',
    InjuryDetailPhysicalEditReport: 'DI7_InjuryDetailPhysical_EditReport',
    InjuryDetailSeriousEditReport: 'DI7_InjuryDetailSeriousInj_EditReport',
    pageViewDateReportedToEmp: 'DI7_InjuryReported_PageView',
    timeSpentDateReportedToEmp: 'DI7_InjuryReported_TimeSpent',
    timeSpentDateOfInjuryPhysical: 'DI7_InjuryDatePhysical_TimeSpent',
    timeSpentDateOfInjurySerious: 'DI7_InjuryDateSeriousInj_TimeSpent',
    pageViewDateOfInjuryPhysical: 'DI7_InjuryDatePhysical_PageView',
    pageViewDateOfInjurySerious: 'DI7_InjuryDateSeriousInj_PageView',
    pageViewNoiseExpoWorkDetail: 'DI7_InjuryDetailNIHL_PageView',
    timeSpentNoiseExpoWorkDetail: 'DI7_InjuryDetailNIHL_TimeSpent',
    pageViewInjuryIllnessWithDocumentUpload: 'DI7_InjuryDetailGradual_PageView',
    timeSpentInjuryIllnessWithDocumentUpload: 'DI7_InjuryDetailGradual_TimeSpent',
    pageViewDateOfInjuryIllness: 'DI7_InjuryDateGradual_PageView',
    timeSpentDateOfInjuryIllness: 'DI7_InjuryDateGradual_TimeSpent',
    toolTipDateOfInjuryIllness: 'DI7_InjuryDateGradual_DateTooltip',
    pageViewNihlWorkHistoryInfo: 'DI7_WorkHistoryNIHL_PageView',
    timeSpentNihlWorkHistoryInfo: 'DI7_WorkHistoryNIHL_TimeSpent',
    pageViewNhilDetails: 'DI7_InjuryWorkDetailNIHL_PageView',
    timeSpentNhilDetails: 'DI7_InjuryWorkDetailNIHL_TimeSpent',
    pageViewNIHLWorkInfo: 'DI7_PWINIHL_PageView',
    timeSpentNIHLWorkInfo: 'DI7_PWINIHL_TimeSpent',
    pageViewNIHLTooltip: 'DI7_PWINIHL_EmploymentTooltip',
    injuryDetailsGradualDocSuccess: 'DI7_InjuryDetailGradual_UploadDoc',
    pageViewInjuryDateMental: 'DI7_InjuryDateMental_PageView',
    pageViewDateIllness: 'DI7_InjuryDateIllness_PageView',
    timeSpentDateIllness: 'DI7_InjuryDateIllness_TimeSpent',
    timeSpentInjuryDateMental: 'DI7_InjuryDateMental_TimeSpent',
    pageViewBodyPartsOneTime: 'DI7_BodyPartPhysical_PageView',
    pageViewBodyPartsGradual: 'DI7_BodyPartGradual_PageView',
    pageViewBodyPartsSerious: 'DI7_BodyPartSeriousInj_PageView',
    pageViewBodyPartsMental: 'DI7_BodyPartMental_PageView',
    timeSpentBodyPartsMental: 'DI7_BodyPartMental_TimeSpent',
    timeSpentBodyPartsOneTime: 'DI7_BodyPartPhysical_TimeSpent',
    timeSpentBodyPartsGradual: 'DI7_BodyPartGradual_TimeSpent',
    timeSpentBodyPartsSerious: 'DI7_BodyPartSeriousInj_TimeSpent',
    docUploadSuccessBodyPartsPhysical: 'DI7_BodyPartPhysical_UploadDoc',
    docUploadSuccessBodyPartsGradual: 'DI7_BodyPartGradual_UploadDoc',
    docUploadSuccessBodyPartsSerious: 'DI7_BodyPartSeriousInj_UploadDoc',
    docUploadSuccessBodyPartsMental: 'DI7_BodyPartMental_UploadDoc',
    pageViewProvideInjuryDetailsMental: 'DI7_InjuryDetailMental_PageView',
    timeSpentProvideInjuryDetailsMental: 'DI7_InjuryDetailMental_TimeSpent',
    docUploadSuccessMentalInjuryDetails: 'DI7_InjuryDetailMental_UploadDoc',
    pageViewillnessInjuryDetails: 'DI7_InjuryDetailIllness_PageView',
    timeSpentIllnessInjuryDetails: 'DI7_InjuryDetailIllness_TimeSpent',
    pageViewHavsInjuryDetails: 'DI7_InjuryDetailHAVS_PageView',
    timeSpentHavsInjuryDetails: 'DI7_InjuryDetailHAVS_TimeSpent',
    docUploadSuccessHavsINjuryDetails: 'DI7_InjuryDetailHAVS_UploadDoc',
    employmentHavsToolTip: 'DI7_InjuryDetailHAVS_EmploymentTooltip',
    conversionOfPDFisInProgress: 'DI7_Error_PDFConversionInProgress',
    pageViewIdentifyExposedSubstances: 'DI7_ExposureAddDetail_PageView',
    timeSpentIdentifyExposedSubstance: 'DI7_ExposureAddDetail_TimeSpent',
    infectiousExposedSubstanceSelected: 'DI7_ExposureDetail_InfectousSub',
    chemicalExposureSubstanceSelected: 'DI7_ExposureDetail_ChemicalOther',
    pageViewHealthCare: 'DI7_Healthcare_PageView',
    timeSpentHealthCare: 'DI7_Healthcare_TimeSpent',
    pageVoewReportsConcerns: 'DI7_ReportConcerns_PageView',
    timeSpentReportsConcerns: 'DI7_ReportConcerns_TimeSpent',
    docUploadSuccessReportsConcerns: 'DI7_ReportConcerns_UploadDoc',
    reportsConcernsObjectionsToolTip: 'DI7_ReportConcerns_ObjectionTooltip',
    pageViewIdentifyPriorCLaims: 'DI7_RelatedProblem_PageView',
    timeSpentIdentifyPriorClaims: 'DI7_RelatedProblem_TimeSpent',
    identifySimilarProblemToolTip: 'DI7_RelatedProblem_SimilarTooltip',
    pageViewInjuryIncidentLocation: 'DI7_IncidentLocation_PageView',
    timeSpentInjuryIncidentLocation: 'DI7_IncidentLocation_TimeSpent',
    pageViewWitnessInformation: 'DI7_Witness_PageView',
    witnessInvolvedToolTip: 'DI7_Witness_InvolvedTooltip',
    witnessNoPhoneToolTip: 'DI7_Witness_NoPhoneNumberTooltip',
    timeSpentWitnessInformation: 'DI7_Witness_TimeSpent',
    pageViewNextKinEmergencyContact: 'DI7_FatalityNOK_PageView',
    timeSpentNextKinEmergencyContact: 'DI7_FatalityNOK_TimeSpent',
    pageViewFatalityLocation: 'DI7_FatalityLocation_PageView',
    timeSpentFatalityLocation: 'DI7_FatalityLocation_TimeSpent',
    FatalityLocationNotFoundWarning: 'DI7_Warning_OrgAddrNoMatch',
    deleteDraftReport: 'DI7_Error_SystemDeleteDraft',
    pageViewReportToPolice: 'DI7_FatalityPolice_PageView',
    timeSpentReportToPolice: 'DI7_FatalityPolice_TimeSpent',
    pageViewMinistryOfLabour: 'DI7_FatalityMOL_PageView',
    timeSpentMinistryOfLabour: 'DI7_FatalityMOL_TimeSpent',
    ministryOfLabourDocSuccess: 'DI7_FatalityMOL_UploadDoc',
    ministryOfLabourToolTip: 'DI7_FatalityMOL_InvolvedTooltip',
    fatalityDatePageView: 'DI7_FatalityDate_PageView',
    fatalityDateTimeSpent: 'DI7_FatalityDate_TimeSpent',
    pageViewFatalityDetails: 'DI7_FatalityDetail_PageView',
    timeSpentFatalityDetails: 'DI7_FatalityDetail_TimeSpent',
    fatalityDocUploadSuccess: 'DI7_FatalityDetail_UploadDoc',
    pageViewFatalityContactDetails: 'DI7_InjuryReportedContact_PageView',
    timeSpentFatalityContactDetails: 'DI7_InjuryReportedContact_TimeSpent',
    di7Faqcategory1_title1: 'DI7_FAQ_Category1_Title1_1',
    di7Faqcategory1_title2: 'DI7_FAQ_Category1_Title1_2',
    di7Faqcategory1_title3: 'DI7_FAQ_Category1_Title1_3',
    di7Faqcategory1_title4: 'DI7_FAQ_Category1_Title1_4',
    di7Faqcategory1_title5: 'DI7_FAQ_Category1_Title1_5',
    di7Faqcategory1_title6: 'DI7_FAQ_Category1_Title1_6',
    di7Faqcategory1_title7: 'DI7_FAQ_Category1_Title1_7',
    di7Faqcategory1_title21: 'DI7_FAQ_Category1_Title2_1',
    di7Faqcategory2_title1: 'DI7_FAQ_Category2_Title1_1',
    di7Faqcategory2_title2: 'DI7_FAQ_Category2_Title1_2',
    di7Faqcategory2_title3: 'DI7_FAQ_Category2_Title1_3',
    di7Faqcategory2_title4: 'DI7_FAQ_Category2_Title1_4',
    di7Faqcategory2_Title21: 'DI7_FAQ_Category2_Title2_1',
    di7Faqcategory2_Title22: 'DI7_FAQ_Category2_Title2_2',
    di7Faqcategory2_Title23: 'DI7_FAQ_Category2_Title2_3',
    manageYourAccountHelpLink: 'DI7_HelpfulLinks_ManageAccount',
    faqHelpLink: 'DI7_HelpfulLinks_FAQ',
    premiumPaymentHelpLink: 'DI7_HelpfulLinks_PremiumPayment',
    healthCareHelpLink: 'DI7_HelpfulLinks_HealthSafety',
    lostTimeEarningsPageView: 'DI7_LostTime_PageView',
    lostTimeEarningsPageTimespent: 'DI7_LostTime_TimeSpent',
    lossDateChangeWarningMessage: 'DI7_LostTime_LossDateChange',
    vehicleAccidentDetailsPageView: 'DI7_MVADetails_PageView',
    vehicleAccidentDetailsPageTimeSpent: 'DI7_MVADetails_TimeSpent',
    assaultDetailsPageView: 'DI7_AssaultDetails_PageView',
    assaultDetailsPageTimeSpent: 'DI7_AssaultDetails_TimeSpent',
    malFunctionPageView: 'DI7_Malfunction_PageView',
    malFunctionPageTimeSpent: 'DI7_Malfunction_TimeSpent',
    modifiedWorkPageView: 'DI7_ModifiedWork_PageView',
    modifiedWorkPageTimeSpent: 'DI7_ModifiedWork_TimeSpent',
    modifiedWorkDocSubmitted: 'DI7_ModifiedWork_UploadDoc',
    modifiedWorkMWtooltip: 'DI7_ModifiedWork_MWTooltip',
    modifiedWorkFormalOffToolTip: 'DI7_ModifiedWork_FormalOffTooltip',
    employementTypePageView: 'DI7_EmploymentType_PageView',
    employmentTypePageTimeSpent: 'DI7_EmploymentType_TimeSpent',
    employmentTypeToolTip1: 'DI7_EmploymentType_Tooltip1',
    employmentTypeToolTip2: 'DI7_EmploymentType_Tooltip2',
    employmentTypeToolTip3: 'DI7_EmploymentType_Tooltip3',
    employmentTypeToolTip4: 'DI7_EmploymentType_Tooltip4',
    employmentTypeToolTip5: 'DI7_EmploymentType_Tooltip5',
    employmentTypeToolTip6: 'DI7_EmploymentType_Tooltip6',
    employmentTypeToolTip7: 'DI7_EmploymentType_Tooltip7',
    employmentTypeToolTip8: 'DI7_EmploymentType_Tooltip8',
    employmentTypeToolTip9: 'DI7_EmploymentType_Tooltip9',
    employmentTypeToolTip10: 'DI7_EmploymentType_Tooltip10',
    employmentTypeToolTip11: 'DI7_EmploymentType_Tooltip11',
    employmentTypeToolTip12: 'DI7_EmploymentType_Tooltip12',
    employmentTypeToolTip13: 'DI7_EmploymentType_Tooltip13',
    employmentTypeToolTip14: 'DI7_EmploymentType_Tooltip14',
    workSchedulePageView: 'DI7_WorkSchedule_PageView',
    workSchedulePageTimeSpent: 'DI7_WorkSchedule_TimeSpent',
    lossTimePayPageView: 'DI7_LostTimePay_PageView',
    lossTimePayPageTimeSpent: 'DI7_LostTimePay_TimeSpent',
    regularWorkSchedulePageView: 'DI7_RegularWorkSch_PageView',
    regularWorkSchedulePageTimespent: 'DI7_RegularWorkSch_TimeSpent',
    workshiftSchedulePageView: 'DI7_ShiftWorkSch_PageView',
    workShiftSchedulePageTimeSpent: 'DI7_ShiftWorkSch_TimeSpent',
    workShiftSCheduleLostHoursTimeSpent: 'DI7_ShiftWorkSch_HoursLostTooltip',
    irregularWorkSchedulePageView: 'DI7_IrregWorkSch_PageView',
    irregularWorkSchedulePageTimeSpent: 'DI7_IrregWorkSch_TimeSpent',
    additionalEarningsPageView: 'DI7_AdditionalPWIEarnings_PageView',
    additionalEarningsPageTimeSpent: 'DI7_AdditionalPWIEarnings_TimeSpent',
    otherEarningsPageView: 'DI7_OtherEarning_PageView',
    otherEarningPageTimeSpent: 'DI7_OtherEarning_TimeSpent',
    otherEarningsToolTip1: 'DI7_OtherEarning_Tooltip1',
    otherEarningsToolTip2: 'DI7_OtherEarning_Tooltip2',
    otherEarningsToolTip3: 'DI7_OtherEarning_Tooltip3',
    otherEarningsToolTip4: 'DI7_OtherEarning_Tooltip4',
    otherEarningsToolTip5: 'DI7_OtherEarning_Tooltip5',
    otherEarningsToolTip6: 'DI7_OtherEarning_Tooltip6',
    otherEarningsToolTip7: 'DI7_OtherEarning_Tooltip7',
    otherEarningsToolTip8: 'DI7_OtherEarning_Tooltip8',
    otherEarningsToolTip9: 'DI7_OtherEarning_Tooltip9',
    otherEarningsToolTip10: 'DI7_OtherEarning_Tooltip10',
    otherEarningsToolTip11: 'DI7_OtherEarning_Tooltip11',
    otherEarningsToolTip12: 'DI7_OtherEarning_Tooltip12',
    otherEarningsToolTip13: 'DI7_OtherEarning_Tooltip13',
    otherEarningsToolTip14: 'DI7_OtherEarning_Tooltip14',
    otherEarningsDetailsPageView: 'DI7_OtherEarningDetails_PageView',
    otherEarningsDetailsPageTimeSpent: 'DI7_OtherEarningDetails_TimeSpent',
    otherEarningsDocumentSuccess: 'DI7_OtherEarningDetails_UploadDoc',
    LostTimeRecoveryPageView: 'DI7_LostTimeRecovery_PageView',
    LostTimeRecoveryPageTimeSPent: 'DI7_LostTimeRecovery_TimeSpent',
    lostTimeRecoveryToolTip: 'DI7_LostTimeRecovery_GrossAdvanceTooltip',
    NECPageView: 'DI7_NEC_PageView',
    NECPageTimeSpent: 'DI7_NEC_TimeSpent',
    NECToolTip: 'DI7_NEC_NECTooltip',
    vacationPayPageView: 'DI7_VacationPay_PageView',
    vacationPayPageTimeSpent: 'DI7_VacationPay_TimeSpent',
    vacationPayContactToolTip: 'DI7_VacationPay_ContactTooltip',
    shortTermPageView: 'DI7_STDC_PageView',
    shortTermPageTimeSpent: 'DI7_STDC_TimeSpent',
    vacationIllnessPageView: 'DI7_LostTimeVacationIllness_PageView',
    vacationIllnessPageTimeSpent: 'DI7_LostTimeVacationIllness_TimeSpent',
    vacationIllnessToolTip: 'DI7_LostTime4WeeksPrior_LostTimeTooltip',
    additionalCommentsPageView: 'DI7_AdditionalComments_PageView',
    additionalCommentsPageTimeSPent: 'DI7_AdditionalComments_TimeSpent',
    supportingDocumentsPageView: 'DI7_SupportingDoc_PageView',
    supportingDocumentsPageTimeSpent: 'DI7_SupportingDoc_TimeSpent',
    supportingDocumentSubmission: 'DI7_SupportingDoc_UploadDoc',
    DOAPageView: 'DI7_SupportingDoc_DOA_PageView',
    DOAPageTimeSpent: 'DI7_SupportingDoc_DOA_TimeSpent',
    DOADocumentSubmitted: 'DI7_SupportingDoc_DOA_UploadDoc',
    onFatalityReportChange: 'DI7_SelectReportType_Fatality',
    onPhysicalCategoryChange: 'DI7_SelectCatInjuryIllness_Physical',
    onIllnessCategoryChange: 'DI7_SelectCatInjuryIllness_Illness',
    onMentalHealthCategoryChange: 'DI7_SelectCatInjuryIllness_MentalHealth',
    onNihlCategoryChange: 'DI7_SelectCatInjuryIllness_NIHL',
    onSeriousInjuryCategoryChange: 'DI7_SelectCatInjuryIllness_SeriousInj',
    onCIERCategoryChange: 'DI7_SelectCatExposure_SelCEIR',
    onPEIRCategoryChange: 'DI7_SelectCatExposure_SelPEIR',
    GECAPageview: 'DI7_GECAReport_PageView',
    GECAPageViewTimeSpent: 'DI7_GECAReport_TimeSpent',
    electingTitleToolTip: 'DI7_GECAReport_ElectingTooltip',
    GECACounterSignToolTip: 'DI7_GECAReport_CountersignTooltip',
    confirmationPageView: 'DI7_Complete_PageView',
    confirmationPageTimeSent: 'DI7_Complete_TimeSpent',
    onSurveyLinkClicked: 'DI7_Complete_SurveyLink',
    reviewAndSubmitPageView: 'DI7_ReviewSubmit_PageView',
    reviewAndSubmitPageTimeSpent: 'DI7_ReviewSubmit_TimeSpent',
    unSuccessFulReportSubmission: 'DI7_Error_FailSubmission',
    returnToWorkPageView: 'DI7_LostTimeReturnToWork_PageView',
    returnToWorkPageTimeSpent: 'DI7_LostTimeReturnToWork_TimeSpent',
    navigateAwayDuringIntake: 'DI7_Warning_NavigateAway',
    leaveSessionfromIntake: 'DI7_ExitPage_TimeOutLeaveSession',
    autoLogoutfromIntake: 'DI7_ExitPage_Abandonment',
    generalError: 'DI7_Error_General',
    wafError: 'DI7_Error_403',
    concurrencyError: 'DI7_Error_Concurrency',
    helpIcon: 'DI7_HelpMenu_HelpIcon',
};

const getLinkAnalyticsReport = (key, lang) => {
    // if (lang === 'fr') {
    //     analyticsTag = contentFrench[key];
    // } else {
    //     analyticsTag = contentEnglish[key];
    // }
    const analyticsTag = contentEnglish[key];
    if (!analyticsTag) {
        console.log(`Google analytics: Error: unknown google analytics key - ${key}`);
        throw key;
    }
    return analyticsTag;
};
export const sendAlternativeAnalyticsReport = (key, lang) => {
    const AnalyticsLinkS = getLinkAnalyticsReport(key, lang);
    // tslint:disable-next-line: one-variable-per-declaration
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
        event: AnalyticsLinkS
    });
};

export const sendTimeSpentAnalyticsReport = (key, lang, timeSpent) => {
    const analyticsTag = getLinkAnalyticsReport(key, lang);
    // tslint:disable-next-line: one-variable-per-declaration
    const dataLayer = window.dataLayer || [];
    const payload = {
        event: timeSpent,
        event_category: analyticsTag
    };

    dataLayer.push(payload);
};
export const sendTimeSpentTag = (key, lang) => {
    const startTime = new Date().getTime();
    return () => {
        const diff = new Date().getTime() - startTime;
        const timeSpent = Math.round(diff / 1000);
        sendTimeSpentAnalyticsReport(
            key,
            lang,
            timeSpent
        );
    };
};
