/* eslint-disable no-unused-expressions */
// eslint-disable-next-line no-unused-vars
import React, {
    useEffect, useContext, useCallback
} from 'react';
import {
    renderContentFromMetadata,
    validateMetadata,
} from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import styles from './loader.module.scss';
import uiMetadata from './loader.metadata.json5';
import message from './loader.messages';


function LoaderComponent({ showLoader }) {
    const translator = useContext(TranslatorContext);

    /**
     * @param {string} key
     * @returns content
     * get content on the basis of key
     */
    const fetchContent = useCallback(
        (key) => {
            return translator(message[key]);
        },
        [translator]
    );

    /* React useEffect hook */
    useEffect(() => {
        validateMetadata();
        if (showLoader === true) {
            document?.getElementById('pageLoader')?.setAttribute('role', 'alert');
        } else if (showLoader === false) {
            document?.getElementById('pageLoaderCompleted')?.setAttribute('role', 'alert');
        }
    }, [showLoader]);

    /* to override values at run time */
    const override = {
        loader: {
            visible: showLoader
        },
        loaderContent: {
            content: fetchContent('loadingContent'),
        },
        loaderMainContainer: {
            visible: showLoader
        },
        pageLoaderCompleted: {
            visible: showLoader,
            'aria-label': fetchContent('loadingContentCompleted'),
        },
        pageLoader: {
            visible: showLoader,
            'aria-label': fetchContent('loadingContent'),
        }
    };

    /* to resolve custom styles and callback functions */
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        }
    };

    /* to render the metadata */
    return renderContentFromMetadata(uiMetadata.loader, override, resolvers);
}

export default LoaderComponent;
