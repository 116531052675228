import { defineMessages } from 'react-intl';

export default defineMessages({
    heading: {
        id: 'account-send-message.heading',
        defaultMessage: 'Send us a message'
    },
    para1: {
        id: 'account-send-message.para1',
        defaultMessage:
      'If you have a question related to this account, select a subject from the drop-down menu and type your question in the text box. All information you share will be added to the account file.'
    },
    businessServicesAnnoucement9: {
        id: 'account-send-message.businessServicesAnnoucement9',
        defaultMessage: 'Learn about our ',
    },
    businessServicesAnnoucement10: {
         id: 'account-send-message.businessServicesAnnoucement10',
         defaultMessage: '2025 surplus rebate for businesses',
    },
    businessServicesAnnoucement11: {
         id: 'account-send-message.businessServicesAnnoucement11',
         defaultMessage: ', including how to ',
    },
    businessServicesAnnoucement12: {
        id: 'account-send-message.businessServicesAnnoucement12',
        defaultMessage: 'request a cheque ',
    },
    businessServicesAnnoucement13: {
        id: 'account-send-message.businessServicesAnnoucement13',
        defaultMessage: ' for any credits on your account.',
    },
    para2: {
        id: 'account-send-message.para2',
        defaultMessage:
      ' A customer service representative will respond to your message within'
    },
    para3: {
        id: 'account-send-message.para3',
        defaultMessage: 'for more information.'
    },
    para4: {
        id: 'account-send-message.para4',
        defaultMessage: 'one business day. Please see our'
    },
    two: {
        id: 'account-send-message.two',
        defaultMessage: 'one'
    },
    FAQLink: {
        id: 'account-send-message.FAQLink',
        defaultMessage: 'frequently asked questions'
    },
    para5: {
        id: 'account-send-message.para5',
        defaultMessage: 'Please don\'t send any claims-related messages as we can\'t respond to claims-related questions through this service. Go to  '
    },
    viewClaimsLink: {
        id: 'account-send-message.viewClaimsLink',
        defaultMessage: 'View your claims'
    },
    para6: {
        id: 'account-send-message.para6',
        defaultMessage: ' to send us a claim-related message.'
    },
    requiredContent: {
        id: 'account-send-message.requiredContent',
        defaultMessage: 'You must enter a subject and message to send us your message.'
    },
    Subject: {
        id: 'account-send-message.Subject',
        defaultMessage: 'Subject:'
    },
    Body: {
        id: 'account-send-message.Body',
        defaultMessage: 'Message:'
    },
    Send: {
        id: 'account-send-message.Send',
        defaultMessage: 'Send message'
    },
    contactUsText: {
        id: 'account-send-message.contactUsText',
        defaultMessage: 'If you have an immediate request or a question about your account, please '
    },
    contactUsLink: {
        id: 'account-send-message.contactUsLink',
        defaultMessage: 'contact us.'
    },
    ChooseOne: {
        id: 'account-send-message.Chooseone',
        defaultMessage: 'Choose one'
    },
    AccountMaintenance: {
        id: 'account-send-message.AccountMaintenance',
        defaultMessage: 'Account maintenance (i.e., closure, ownership, address changes)'
    },
    Clearance: {
        id: 'account-send-message.Clearance',
        defaultMessage: 'Clearances'
    },
    PremiumAccounts: {
        id: 'account-send-message.PremiumAccounts',
        defaultMessage: 'Reporting and paying premiums'
    },
    PremiumRates: {
        id: 'account-send-message.PremiumRates',
        defaultMessage: 'Premium rates/classification'
    },
    Registration: {
        id: 'account-send-message.Registration',
        defaultMessage: 'Registration'
    },
    WorkerStatus: {
        id: 'account-send-message.WorkerStatus',
        defaultMessage: 'Worker/independent operator status'
    },
    MSG41: {
        id: 'account-send-message.MSG41',
        defaultMessage:
      'Please try again later. Please call us at 1-800-387-0750 if you’re still having trouble with this page.'
    },
    MSG22: {
        id: 'account-send-message.MSG22',
        defaultMessage:
      'Are you sure you want to leave this page?\nYou’ll lose the information you entered.'
    },
    StayOnPage: {
        id: 'account-send-message.StayOnPage',
        defaultMessage: 'Stay on this page'
    },
    LeavePage: {
        id: 'account-send-message.LeavePage',
        defaultMessage: 'Leave this page'
    },
    MSG79: {
        id: 'account-send-message.MSG79',
        defaultMessage:
      'Are you sure you want to continue?\nYou’ll lose the information you entered.'
    },
    Okay: {
        id: 'account-send-message.Okay',
        defaultMessage: 'Okay'
    },
    Cancel: {
        id: 'account-send-message.Cancel',
        defaultMessage: 'Cancel'
    },
    characterCount: {
        id: 'account-send-message.characterCount',
        defaultMessage: 'Character count'
    },
    Schedule2: {
        id: 'account-send-message.Schedule2',
        defaultMessage: 'Schedule2'

    },
    schedule1: {
        id: 'account-send-message.schedule1',
        defaultMessage: 'Schedule 1'
    },
    schedule2: {
        id: 'account-send-message.schedule2',
        defaultMessage: 'Schedule 2'
    }
});
