import React, { useEffect } from 'react';
import { LocaleService } from '@jutro/locale';
// import custom component header.
import Header from '../header/header';
// import custom component footer.
// import Footer from '../footer/footer';
// import custom component ClaimSummaries.
import SessionExpired from '../session-expired/session-expired';

function SessionExpiredHome() {
    useEffect(() => {
        const interval = setInterval(() => {
            const header = document.querySelector('#herder-main-container');
            if (header && getComputedStyle(header).display !== 'none') {
                if (document.getElementById('footerElement')) {
                    // eslint-disable-next-line no-unused-expressions
                    document.getElementById('footerElement')?.removeAttribute('hidden');
                }
                clearInterval(interval);
            }
        }, 100);
        const footerEle = document.getElementById('footerElement');
        const lang = LocaleService.getStoredLocale();
        // eslint-disable-next-line no-unused-expressions
        footerEle?.setAttribute('lang', lang);
        return () => clearInterval(interval);
    },);
    return (
        <div>
            <Header />
            <div tabIndex="-1" className="osbcMainContainer">
                <SessionExpired />
            </div>
        </div>
    );
}

export default SessionExpiredHome;
